<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<template>
  <login logo="img/login.svg" image="img/login-background.jpg">
    <template #login-actions>
      <div id="iiotForgotPasswordComponent">
        <v-form id="iiotForgotPasswordLeftPart" ref="form" @keyup.enter.native="reset">
          <div class="title mb-7">
            <h1>{{ $t('login.forgotPassword.title') }}</h1>
            <v-divider class="mt-1" />
          </div>
          <v-row>
            <v-text-field
              id="iiotForgotPasswordInputEmail"
              v-model="email"
              :label="$t('login.forgotPassword.emailField')"
              :rules="emailRules"
              :type="inputType"
              class="default-input mb-4"
              required
              autofocus
            />
          </v-row>
          <v-row>
            <span class="mt-6 mb-10">
              {{ $t('login.forgotPassword.note') }}
            </span>
          </v-row>
          <v-col>
            <v-row class="justify-center mt-4">
              <nerve-button
                id="iiotForgotPasswordCancelButton"
                :text="$t('login.forgotPassword.cancelBtn')"
                type-of-btn="cancel"
                size="normal"
                @click-event="goToLoginPage"
              />
            </v-row>
            <v-row class="justify-center mt-4">
              <nerve-button
                id="iiotForgotPasswordResetButton"
                :disabled="!validEmail"
                :text="$t('login.forgotPassword.resetBtn')"
                type-of-btn="action"
                size="normal"
                @click-event="reset()"
              />
            </v-row>
          </v-col>
        </v-form>
      </div>
    </template>
  </login>
</template>
<script>
import { Login, NerveButton } from 'nerve-ui-components';
import { VALIDATION_REGEX } from '@/constants';

export default {
  components: { Login, NerveButton },
  data() {
    return {
      email: '',
      inputType: 'email',
      currentLanguage: '',
      isLdapActive: false,
    };
  },
  computed: {
    validEmail() {
      return VALIDATION_REGEX.EMAIL.test(this.email);
    },
    emailRules() {
      return [
        (v) => !!v || this.$t('login.forgotPassword.requiredRule'),
        ...(this.inputType === 'email' ? [(v) => VALIDATION_REGEX.EMAIL.test(v)] : []),
      ];
    },
  },
  created() {
    this.currentLanguage = localStorage.getItem('language') || 'en_EN';
    this.isLdapActive = localStorage.getItem('isLdapActive');
    if (this.isLdapActive) {
      this.inputType = 'text';
      return;
    }
    this.inputType = 'email';
  },
  methods: {
    async reset() {
      try {
        if (!this.$refs.form.validate()) {
          return;
        }
        await this.$store.dispatch('forgot-password/checkIfExisting', {
          email: this.email,
          language: this.currentLanguage,
        });
        const check = this.$store.getters['forgot-password/getCheck'];
        this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'login.forgotPassword.emailInstructionsSent',
          color: 'success',
          showClose: true,
        });
        if (check) {
          await this.$store.dispatch('forgot-password/forgotPass', {
            email: this.email,
            language: this.currentLanguage,
          });
        }
        setTimeout(() => {
          this.$router.push({ name: 'Login' });
        }, 3000);
      } catch (e) {
        this.$log.debug(e);
      }
    },
    goToLoginPage() {
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>
<style lang="scss" scoped>
#iiotForgotPasswordLeftPart {
  padding: 70px;
  .title {
    > h1 {
      text-align: center;
    }
    > .v-divider {
      margin-left: 45%;
    }
  }
}
</style>
